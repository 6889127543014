import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
	name: 'character',
	initialState: {
		info: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		detail: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		changeclassinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		relifeinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		resetinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		resetvipinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargameid: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		title: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		questdailyinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		}
	},
	reducers: {
		clearStateCharacter(state) {
			state.info.data = null
			state.info.isLoading = false
			state.info.isSuccess = false
			state.info.isError = false
			state.detail.data = null
			state.detail.isLoading = false
			state.detail.isSuccess = false
			state.detail.isError = false
			state.chargameid.data = null
			state.chargameid.isLoading = false
			state.chargameid.isSuccess = false
			state.chargameid.isError = false
		},
		infoLoading(state) {
			state.info.data = null
			state.info.isLoading = true
			state.info.isSuccess = false
			state.info.isError = false
		},
		infoError(state, action) {
			state.info.data = action.payload
			state.info.isLoading = false
			state.info.isSuccess = false
			state.info.isError = true
		},
		infoSuccess(state, action) {
			state.info.data = action.payload
			state.info.isLoading = false
			state.info.isSuccess = true
			state.info.isError = false
		},
		detailLoading(state) {
			state.detail.data = null
			state.detail.isLoading = true
			state.detail.isSuccess = false
			state.detail.isError = false
		},
		detailError(state, action) {
			state.detail.data = action.payload
			state.detail.isLoading = false
			state.detail.isSuccess = false
			state.detail.isError = true
		},
		detailSuccess(state, action) {
			state.detail.data = action.payload
			state.detail.isLoading = false
			state.detail.isSuccess = true
			state.detail.isError = false
		},
		changeClassInfoLoading(state, action) {
			state.changeclassinfo.data = action.payload
			state.changeclassinfo.isLoading = false
			state.changeclassinfo.isSuccess = true
			state.changeclassinfo.isError = false
		},
		changeClassInfoError(state) {
			state.changeclassinfo.data = null
			state.changeclassinfo.isLoading = true
			state.changeclassinfo.isSuccess = false
			state.changeclassinfo.isError = false
		},
		changeClassInfoSuccess(state, action) {
			state.changeclassinfo.data = action.payload
			state.changeclassinfo.isLoading = false
			state.changeclassinfo.isSuccess = false
			state.changeclassinfo.isError = true
		},
		relifeInfoSuccess(state, action) {
			state.relifeinfo.data = action.payload
			state.relifeinfo.isLoading = false
			state.relifeinfo.isSuccess = true
			state.relifeinfo.isError = false
		},
		relifeInfoLoading(state) {
			state.relifeinfo.data = null
			state.relifeinfo.isLoading = true
			state.relifeinfo.isSuccess = false
			state.relifeinfo.isError = false
		},
		relifeInfoError(state, action) {
			state.relifeinfo.data = action.payload
			state.relifeinfo.isLoading = false
			state.relifeinfo.isSuccess = false
			state.relifeinfo.isError = true
		},
		resetInfoSuccess(state, action) {
			state.resetinfo.data = action.payload
			state.resetinfo.isLoading = false
			state.resetinfo.isSuccess = true
			state.resetinfo.isError = false
		},
		resetInfoLoading(state) {
			state.resetinfo.data = null
			state.resetinfo.isLoading = true
			state.resetinfo.isSuccess = false
			state.resetinfo.isError = false
		},
		resetInfoError(state, action) {
			state.resetinfo.data = action.payload
			state.resetinfo.isLoading = false
			state.resetinfo.isSuccess = false
			state.resetinfo.isError = true
		},
		resetVIPInfoSuccess(state, action) {
			state.resetvipinfo.data = action.payload
			state.resetvipinfo.isLoading = false
			state.resetvipinfo.isSuccess = true
			state.resetvipinfo.isError = false
		},
		resetVIPInfoLoading(state) {
			state.resetvipinfo.data = null
			state.resetvipinfo.isLoading = true
			state.resetvipinfo.isSuccess = false
			state.resetvipinfo.isError = false
		},
		resetVIPInfoError(state, action) {
			state.resetvipinfo.data = action.payload
			state.resetvipinfo.isLoading = false
			state.resetvipinfo.isSuccess = false
			state.resetvipinfo.isError = true
		},
		charGameIDLoading(state) {
			state.chargameid.data = null
			state.chargameid.isLoading = true
			state.chargameid.isSuccess = false
			state.chargameid.isError = false
		},
		charGameIDError(state, action) {
			state.chargameid.data = action.payload
			state.chargameid.isLoading = false
			state.chargameid.isSuccess = false
			state.chargameid.isError = true
		},
		charGameIDSuccess(state, action) {
			state.chargameid.data = action.payload
			state.chargameid.isLoading = false
			state.chargameid.isSuccess = true
			state.chargameid.isError = false
		},
		titleInfoLoading(state) {
			state.title.data = null
			state.title.isLoading = true
			state.title.isSuccess = false
			state.title.isError = false
		},
		titleInfoError(state, action) {
			state.title.data = action.payload
			state.title.isLoading = false
			state.title.isSuccess = false
			state.title.isError = true
		},
		titleInfoSuccess(state, action) {
			state.title.data = action.payload
			state.title.isLoading = false
			state.title.isSuccess = true
			state.title.isError = false
		},
		questDailyInfoLoading(state) {
			state.questdailyinfo.data = null
			state.questdailyinfo.isLoading = true
			state.questdailyinfo.isSuccess = false
			state.questdailyinfo.isError = false
		},
		questDailyInfoError(state, action) {
			state.questdailyinfo.data = action.payload
			state.questdailyinfo.isLoading = false
			state.questdailyinfo.isSuccess = false
			state.questdailyinfo.isError = true
		},
		questDailyInfoSuccess(state, action) {
			state.questdailyinfo.data = action.payload
			state.questdailyinfo.isLoading = false
			state.questdailyinfo.isSuccess = true
			state.questdailyinfo.isError = false
		}
	}
})

export default userSlice.reducer

export const {
	clearStateCharacter,
	infoLoading,
	infoError,
	infoSuccess,
	detailLoading,
	detailError,
	detailSuccess,
	changeClassInfoLoading,
	changeClassInfoError,
	changeClassInfoSuccess,
	relifeInfoLoading,
	relifeInfoError,
	relifeInfoSuccess,
	resetInfoLoading,
	resetInfoError,
	resetInfoSuccess,
	resetVIPInfoLoading,
	resetVIPInfoError,
	resetVIPInfoSuccess,
	charGameIDLoading,
	charGameIDError,
	charGameIDSuccess,
	titleInfoLoading,
	titleInfoError,
	titleInfoSuccess,
	questDailyInfoLoading,
	questDailyInfoError,
	questDailyInfoSuccess
} = userSlice.actions
