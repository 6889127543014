import { createSlice } from '@reduxjs/toolkit'

const eventSlice = createSlice({
	name: 'event',
	initialState: {
		history: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		award: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		itemcharge: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		itemdailycharge: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		itemmoneycharge: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		itemmonthcharge: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		itemyearcharge: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargeinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargedailyinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargemoneyinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargemonthinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		chargeyearinfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		goblininfo: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		goblinlist: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		goblinfinish: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		}
	},
	reducers: {
		clearStateEvent(state) {
			state.history.data = null
			state.history.isLoading = false
			state.history.isSuccess = false
			state.history.isError = false
			state.award.data = null
			state.award.isLoading = false
			state.award.isSuccess = false
			state.award.isError = false
			state.chargeinfo.data = null
			state.chargeinfo.isLoading = false
			state.chargeinfo.isSuccess = false
			state.chargeinfo.isError = false
			state.chargedailyinfo.data = null
			state.chargedailyinfo.isLoading = false
			state.chargedailyinfo.isSuccess = false
			state.chargedailyinfo.isError = false
			state.chargemoneyinfo.data = null
			state.chargemoneyinfo.isLoading = false
			state.chargemoneyinfo.isSuccess = false
			state.chargemoneyinfo.isError = false
			state.chargemonthinfo.data = null
			state.chargemonthinfo.isLoading = false
			state.chargemonthinfo.isSuccess = false
			state.chargemonthinfo.isError = false
			state.chargeyearinfo.data = null
			state.chargeyearinfo.isLoading = false
			state.chargeyearinfo.isSuccess = false
			state.chargeyearinfo.isError = false
		},
		goblinConfigLoading(state) {
			state.goblininfo.data = null
			state.goblininfo.isLoading = true
			state.goblininfo.isSuccess = false
			state.goblininfo.isError = false
		},
		goblinConfigError(state, action) {
			state.goblininfo.data = action.payload
			state.goblininfo.isLoading = false
			state.goblininfo.isSuccess = false
			state.goblininfo.isError = true
		},
		goblinConfigSuccess(state, action) {
			state.goblininfo.data = action.payload
			state.goblininfo.isLoading = false
			state.goblininfo.isSuccess = true
			state.goblininfo.isError = false
		},
		goblinListLoading(state) {
			state.goblinlist.data = null
			state.goblinlist.isLoading = true
			state.goblinlist.isSuccess = false
			state.goblinlist.isError = false
		},
		goblinListError(state, action) {
			state.goblinlist.data = action.payload
			state.goblinlist.isLoading = false
			state.goblinlist.isSuccess = false
			state.goblinlist.isError = true
		},
		goblinListSuccess(state, action) {
			state.goblinlist.data = action.payload
			state.goblinlist.isLoading = false
			state.goblinlist.isSuccess = true
			state.goblinlist.isError = false
		},
		goblinFinishLoading(state) {
			state.goblinfinish.data = null
			state.goblinfinish.isLoading = true
			state.goblinfinish.isSuccess = false
			state.goblinfinish.isError = false
		},
		goblinFinishError(state, action) {
			state.goblinfinish.data = action.payload
			state.goblinfinish.isLoading = false
			state.goblinfinish.isSuccess = false
			state.goblinfinish.isError = true
		},
		goblinFinishSuccess(state, action) {
			state.goblinfinish.data = action.payload
			state.goblinfinish.isLoading = false
			state.goblinfinish.isSuccess = true
			state.goblinfinish.isError = false
		},
		giftHistoryLoading(state) {
			state.history.data = null
			state.history.isLoading = true
			state.history.isSuccess = false
			state.history.isError = false
		},
		giftHistoryError(state, action) {
			state.history.data = action.payload
			state.history.isLoading = false
			state.history.isSuccess = false
			state.history.isError = true
		},
		giftHistorySuccess(state, action) {
			state.history.data = action.payload
			state.history.isLoading = false
			state.history.isSuccess = true
			state.history.isError = false
		},
		awardListLoading(state) {
			state.award.data = null
			state.award.isLoading = true
			state.award.isSuccess = false
			state.award.isError = false
		},
		awardListError(state, action) {
			state.award.data = action.payload
			state.award.isLoading = false
			state.award.isSuccess = false
			state.award.isError = true
		},
		awardListSuccess(state, action) {
			state.award.data = action.payload
			state.award.isLoading = false
			state.award.isSuccess = true
			state.award.isError = false
		},
		itemChargeLoading(state) {
			state.itemcharge.data = null
			state.itemcharge.isLoading = true
			state.itemcharge.isSuccess = false
			state.itemcharge.isError = false
		},
		itemChargeError(state, action) {
			state.itemcharge.data = action.payload
			state.itemcharge.isLoading = false
			state.itemcharge.isSuccess = false
			state.itemcharge.isError = true
		},
		itemChargeSuccess(state, action) {
			state.itemcharge.data = action.payload
			state.itemcharge.isLoading = false
			state.itemcharge.isSuccess = true
			state.itemcharge.isError = false
		},
		itemChargeDailyLoading(state) {
			state.itemdailycharge.data = null
			state.itemdailycharge.isLoading = true
			state.itemdailycharge.isSuccess = false
			state.itemdailycharge.isError = false
		},
		itemChargeDailyError(state, action) {
			state.itemdailycharge.data = action.payload
			state.itemdailycharge.isLoading = false
			state.itemdailycharge.isSuccess = false
			state.itemdailycharge.isError = true
		},
		itemChargeDailySuccess(state, action) {
			state.itemdailycharge.data = action.payload
			state.itemdailycharge.isLoading = false
			state.itemdailycharge.isSuccess = true
			state.itemdailycharge.isError = false
		},
		itemChargeMoneyLoading(state) {
			state.itemmoneycharge.data = null
			state.itemmoneycharge.isLoading = true
			state.itemmoneycharge.isSuccess = false
			state.itemmoneycharge.isError = false
		},
		itemChargeMoneyError(state, action) {
			state.itemmoneycharge.data = action.payload
			state.itemmoneycharge.isLoading = false
			state.itemmoneycharge.isSuccess = false
			state.itemmoneycharge.isError = true
		},
		itemChargeMoneySuccess(state, action) {
			state.itemmoneycharge.data = action.payload
			state.itemmoneycharge.isLoading = false
			state.itemmoneycharge.isSuccess = true
			state.itemmoneycharge.isError = false
		},
		itemChargeMonthLoading(state) {
			state.itemmonthcharge.data = null
			state.itemmonthcharge.isLoading = true
			state.itemmonthcharge.isSuccess = false
			state.itemmonthcharge.isError = false
		},
		itemChargeMonthError(state, action) {
			state.itemmonthcharge.data = action.payload
			state.itemmonthcharge.isLoading = false
			state.itemmonthcharge.isSuccess = false
			state.itemmonthcharge.isError = true
		},
		itemChargeMonthSuccess(state, action) {
			state.itemmonthcharge.data = action.payload
			state.itemmonthcharge.isLoading = false
			state.itemmonthcharge.isSuccess = true
			state.itemmonthcharge.isError = false
		},
		itemChargeYearLoading(state) {
			state.itemyearcharge.data = null
			state.itemyearcharge.isLoading = true
			state.itemyearcharge.isSuccess = false
			state.itemyearcharge.isError = false
		},
		itemChargeYearError(state, action) {
			state.itemyearcharge.data = action.payload
			state.itemyearcharge.isLoading = false
			state.itemyearcharge.isSuccess = false
			state.itemyearcharge.isError = true
		},
		itemChargeYearSuccess(state, action) {
			state.itemyearcharge.data = action.payload
			state.itemyearcharge.isLoading = false
			state.itemyearcharge.isSuccess = true
			state.itemyearcharge.isError = false
		},
		infoChargeLoading(state) {
			state.chargeinfo.data = null
			state.chargeinfo.isLoading = true
			state.chargeinfo.isSuccess = false
			state.chargeinfo.isError = false
		},
		infoChargeError(state, action) {
			state.chargeinfo.data = action.payload
			state.chargeinfo.isLoading = false
			state.chargeinfo.isSuccess = false
			state.chargeinfo.isError = true
		},
		infoChargeSuccess(state, action) {
			state.chargeinfo.data = action.payload
			state.chargeinfo.isLoading = false
			state.chargeinfo.isSuccess = true
			state.chargeinfo.isError = false
		},
		infoChargeDailyLoading(state) {
			state.chargedailyinfo.data = null
			state.chargedailyinfo.isLoading = true
			state.chargedailyinfo.isSuccess = false
			state.chargedailyinfo.isError = false
		},
		infoChargeDailyError(state, action) {
			state.chargedailyinfo.data = action.payload
			state.chargedailyinfo.isLoading = false
			state.chargedailyinfo.isSuccess = false
			state.chargedailyinfo.isError = true
		},
		infoChargeDailySuccess(state, action) {
			state.chargedailyinfo.data = action.payload
			state.chargedailyinfo.isLoading = false
			state.chargedailyinfo.isSuccess = true
			state.chargedailyinfo.isError = false
		},
		infoChargeMoneyLoading(state) {
			state.chargemoneyinfo.data = null
			state.chargemoneyinfo.isLoading = true
			state.chargemoneyinfo.isSuccess = false
			state.chargemoneyinfo.isError = false
		},
		infoChargeMoneyError(state, action) {
			state.chargemoneyinfo.data = action.payload
			state.chargemoneyinfo.isLoading = false
			state.chargemoneyinfo.isSuccess = false
			state.chargemoneyinfo.isError = true
		},
		infoChargeMoneySuccess(state, action) {
			state.chargemoneyinfo.data = action.payload
			state.chargemoneyinfo.isLoading = false
			state.chargemoneyinfo.isSuccess = true
			state.chargemoneyinfo.isError = false
		},
		infoChargeMonthLoading(state) {
			state.chargemonthinfo.data = null
			state.chargemonthinfo.isLoading = true
			state.chargemonthinfo.isSuccess = false
			state.chargemonthinfo.isError = false
		},
		infoChargeMonthError(state, action) {
			state.chargemonthinfo.data = action.payload
			state.chargemonthinfo.isLoading = false
			state.chargemonthinfo.isSuccess = false
			state.chargemonthinfo.isError = true
		},
		infoChargeMonthSuccess(state, action) {
			state.chargemonthinfo.data = action.payload
			state.chargemonthinfo.isLoading = false
			state.chargemonthinfo.isSuccess = true
			state.chargemonthinfo.isError = false
		},
		infoChargeYearLoading(state) {
			state.chargeyearinfo.data = null
			state.chargeyearinfo.isLoading = true
			state.chargeyearinfo.isSuccess = false
			state.chargeyearinfo.isError = false
		},
		infoChargeYearError(state, action) {
			state.chargeyearinfo.data = action.payload
			state.chargeyearinfo.isLoading = false
			state.chargeyearinfo.isSuccess = false
			state.chargeyearinfo.isError = true
		},
		infoChargeYearSuccess(state, action) {
			state.chargeyearinfo.data = action.payload
			state.chargeyearinfo.isLoading = false
			state.chargeyearinfo.isSuccess = true
			state.chargeyearinfo.isError = false
		}
	}
})

export default eventSlice.reducer

export const {
	clearStateEvent,
	goblinConfigLoading,
	goblinConfigError,
	goblinConfigSuccess,
	goblinListLoading,
	goblinListError,
	goblinListSuccess,
	goblinFinishLoading,
	goblinFinishError,
	goblinFinishSuccess,
	giftHistoryLoading,
	giftHistoryError,
	giftHistorySuccess,
	awardListLoading,
	awardListError,
	awardListSuccess,
	itemChargeLoading,
	itemChargeError,
	itemChargeSuccess,
	itemChargeDailyLoading,
	itemChargeDailyError,
	itemChargeDailySuccess,
	itemChargeMoneyLoading,
	itemChargeMoneyError,
	itemChargeMoneySuccess,
	itemChargeMonthLoading,
	itemChargeMonthError,
	itemChargeMonthSuccess,
	itemChargeYearLoading,
	itemChargeYearError,
	itemChargeYearSuccess,
	infoChargeLoading,
	infoChargeError,
	infoChargeSuccess,
	infoChargeDailyLoading,
	infoChargeDailyError,
	infoChargeDailySuccess,
	infoChargeMoneyLoading,
	infoChargeMoneyError,
	infoChargeMoneySuccess,
	infoChargeMonthLoading,
	infoChargeMonthError,
	infoChargeMonthSuccess,
	infoChargeYearLoading,
	infoChargeYearError,
	infoChargeYearSuccess
} = eventSlice.actions
