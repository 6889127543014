import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		login: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		logout: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		}
	},
	reducers: {
		loginLoading(state) {
			state.login.data = null
			state.login.isLoading = true
			state.login.isSuccess = false
			state.login.isError = false
		},
		loginError(state, action) {
			state.login.data = action.payload
			state.login.isLoading = false
			state.login.isSuccess = false
			state.login.isError = true
		},
		loginSuccess(state, action) {
			state.login.data = action.payload
			state.login.isLoading = false
			state.login.isSuccess = true
			state.login.isError = false
		},
		logoutLoading(state) {
			state.logout.data = null
			state.logout.isLoading = true
			state.logout.isSuccess = false
			state.logout.isError = false
		},
		logoutError(state, action) {
			state.logout.data = action.payload
			state.logout.isLoading = false
			state.logout.isSuccess = false
			state.logout.isError = true
		},
		logoutSuccess(state, action) {
			state.logout.data = action.payload
			state.logout.isLoading = false
			state.logout.isSuccess = true
			state.login.isSuccess = false
			state.login.data = null
			state.logout.isError = false
		}
	}
})

export default authSlice.reducer

export const { loginLoading, loginError, loginSuccess, logoutLoading, logoutError, logoutSuccess } = authSlice.actions
