import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
	name: 'character',
	initialState: {
		card: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		clearpk: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		changeclass: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		changename: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		relife: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		reset: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		resetmaster: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		resetpoint: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		resetvip: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		title: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		},
		event: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		}
	},
	reducers: {
		configCardLoading(state) {
			state.card.data = null
			state.card.isLoading = true
			state.card.isSuccess = false
			state.card.isError = false
		},
		configCardError(state, action) {
			state.card.data = action.payload
			state.card.isLoading = false
			state.card.isSuccess = false
			state.card.isError = true
		},
		configCardSuccess(state, action) {
			state.card.data = action.payload
			state.card.isLoading = false
			state.card.isSuccess = true
			state.card.isError = false
		},
		configClearPKLoading(state) {
			state.clearpk.data = null
			state.clearpk.isLoading = true
			state.clearpk.isSuccess = false
			state.clearpk.isError = false
		},
		configClearPKError(state, action) {
			state.clearpk.data = action.payload
			state.clearpk.isLoading = false
			state.clearpk.isSuccess = false
			state.clearpk.isError = true
		},
		configClearPKSuccess(state, action) {
			state.clearpk.data = action.payload
			state.clearpk.isLoading = false
			state.clearpk.isSuccess = true
			state.clearpk.isError = false
		},
		configChangeClassLoading(state) {
			state.changeclass.data = null
			state.changeclass.isLoading = true
			state.changeclass.isSuccess = false
			state.changeclass.isError = false
		},
		configChangeClassError(state, action) {
			state.changeclass.data = action.payload
			state.changeclass.isLoading = false
			state.changeclass.isSuccess = false
			state.changeclass.isError = true
		},
		configChangeClassSuccess(state, action) {
			state.changeclass.data = action.payload
			state.changeclass.isLoading = false
			state.changeclass.isSuccess = true
			state.changeclass.isError = false
		},
		configChangeNameLoading(state) {
			state.changename.data = null
			state.changename.isLoading = true
			state.changename.isSuccess = false
			state.changename.isError = false
		},
		configChangeNameError(state, action) {
			state.changename.data = action.payload
			state.changename.isLoading = false
			state.changename.isSuccess = false
			state.changename.isError = true
		},
		configChangeNameSuccess(state, action) {
			state.changename.data = action.payload
			state.changename.isLoading = false
			state.changename.isSuccess = true
			state.changename.isError = false
		},
		configRelifeLoading(state) {
			state.relife.data = null
			state.relife.isLoading = true
			state.relife.isSuccess = false
			state.relife.isError = false
		},
		configRelifeError(state, action) {
			state.relife.data = action.payload
			state.relife.isLoading = false
			state.relife.isSuccess = false
			state.relife.isError = true
		},
		configRelifeSuccess(state, action) {
			state.relife.data = action.payload
			state.relife.isLoading = false
			state.relife.isSuccess = true
			state.relife.isError = false
		},
		configResetLoading(state) {
			state.reset.data = null
			state.reset.isLoading = true
			state.reset.isSuccess = false
			state.reset.isError = false
		},
		configResetError(state, action) {
			state.reset.data = action.payload
			state.reset.isLoading = false
			state.reset.isSuccess = false
			state.reset.isError = true
		},
		configResetSuccess(state, action) {
			state.reset.data = action.payload
			state.reset.isLoading = false
			state.reset.isSuccess = true
			state.reset.isError = false
		},
		configResetMasterLoading(state) {
			state.resetmaster.data = null
			state.resetmaster.isLoading = true
			state.resetmaster.isSuccess = false
			state.resetmaster.isError = false
		},
		configResetMasterError(state, action) {
			state.resetmaster.data = action.payload
			state.resetmaster.isLoading = false
			state.resetmaster.isSuccess = false
			state.resetmaster.isError = true
		},
		configResetMasterSuccess(state, action) {
			state.resetmaster.data = action.payload
			state.resetmaster.isLoading = false
			state.resetmaster.isSuccess = true
			state.resetmaster.isError = false
		},
		configResetPointLoading(state) {
			state.resetpoint.data = null
			state.resetpoint.isLoading = true
			state.resetpoint.isSuccess = false
			state.resetpoint.isError = false
		},
		configResetPointError(state, action) {
			state.resetpoint.data = action.payload
			state.resetpoint.isLoading = false
			state.resetpoint.isSuccess = false
			state.resetpoint.isError = true
		},
		configResetPointSuccess(state, action) {
			state.resetpoint.data = action.payload
			state.resetpoint.isLoading = false
			state.resetpoint.isSuccess = true
			state.resetpoint.isError = false
		},
		configResetVIPLoading(state) {
			state.resetvip.data = null
			state.resetvip.isLoading = true
			state.resetvip.isSuccess = false
			state.resetvip.isError = false
		},
		configResetVIPError(state, action) {
			state.resetvip.data = action.payload
			state.resetvip.isLoading = false
			state.resetvip.isSuccess = false
			state.resetvip.isError = true
		},
		configResetVIPSuccess(state, action) {
			state.resetvip.data = action.payload
			state.resetvip.isLoading = false
			state.resetvip.isSuccess = true
			state.resetvip.isError = false
		},
		configTitleLoading(state) {
			state.title.data = null
			state.title.isLoading = true
			state.title.isSuccess = false
			state.title.isError = false
		},
		configTitleError(state, action) {
			state.title.data = action.payload
			state.title.isLoading = false
			state.title.isSuccess = false
			state.title.isError = true
		},
		configTitleSuccess(state, action) {
			state.title.data = action.payload
			state.title.isLoading = false
			state.title.isSuccess = true
			state.title.isError = false
		},
		configEventLoading(state) {
			state.event.data = null
			state.event.isLoading = true
			state.event.isSuccess = false
			state.event.isError = false
		},
		configEventError(state, action) {
			state.event.data = action.payload
			state.event.isLoading = false
			state.event.isSuccess = false
			state.event.isError = true
		},
		configEventSuccess(state, action) {
			state.event.data = action.payload
			state.event.isLoading = false
			state.event.isSuccess = true
			state.event.isError = false
		}
	}
})

export default userSlice.reducer

export const {
	configCardLoading,
	configCardError,
	configCardSuccess,
	configClearPKLoading,
	configClearPKError,
	configClearPKSuccess,
	configChangeNameLoading,
	configChangeNameError,
	configChangeNameSuccess,
	configRelifeLoading,
	configRelifeError,
	configRelifeSuccess,
	configResetLoading,
	configResetError,
	configResetSuccess,
	configResetMasterLoading,
	configResetMasterError,
	configResetMasterSuccess,
	configResetPointLoading,
	configResetPointError,
	configResetPointSuccess,
	configResetVIPLoading,
	configResetVIPError,
	configResetVIPSuccess,
	configTitleLoading,
	configTitleError,
	configTitleSuccess,
	configChangeClassLoading,
	configChangeClassError,
	configChangeClassSuccess,
	configEventLoading,
	configEventError,
	configEventSuccess
} = userSlice.actions
