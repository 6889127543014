import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import accountReducer from './slices/account'
import authReducer from './slices/auth'
import characterReducer from './slices/character'
import configReducer from './slices/config'
import eventReducer from './slices/event'
import layout from './layout'
import navbar from './navbar'

const rootPersistConfig = {
	key: 'root',
	version: 1,
	storage
}

const rootReducer = combineReducers({
	account: accountReducer,
	auth: authReducer,
	character: characterReducer,
	config: configReducer,
	event: eventReducer,
	layout,
	navbar
})

export { rootPersistConfig, rootReducer }
