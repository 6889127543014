import { createSlice } from '@reduxjs/toolkit'

const accountSlice = createSlice({
	name: 'account',
	initialState: {
		info: {
			data: null,
			isLoading: false,
			isSuccess: false,
			isError: false
		}
	},
	reducers: {
		clearStateAccount(state) {
			state.info.data = null
			state.info.isLoading = false
			state.info.isSuccess = false
			state.info.isError = false
		},
		infoLoading(state) {
			state.info.data = null
			state.info.isLoading = true
			state.info.isSuccess = false
			state.info.isError = false
		},
		infoError(state, action) {
			state.info.data = action.payload
			state.info.isLoading = false
			state.info.isSuccess = false
			state.info.isError = true
		},
		infoSuccess(state, action) {
			state.info.data = action.payload
			state.info.isLoading = false
			state.info.isSuccess = true
			state.info.isError = false
		}
	}
})

export default accountSlice.reducer

export const { clearStateAccount, infoLoading, infoError, infoSuccess } = accountSlice.actions
